<template>
  <component-wrapper
    :class="{
      'horizontal-banner-fi': true,
      'horizontal-banner-fi--exp': isEditMode,
      'horizontal-banner-fi--exp-has-image':
        isEditMode && !fields.image?.value?.src.includes('default_image.svg'),
      'horizontal-banner-fi--exp-dark': isEditMode && isDark && !hasdoNotModifyImage,
      'horizontal-banner-fi--exp-light': isEditMode && isLight && !hasdoNotModifyImage,
    }"
  >
    <component
      :is="link ? 'a' : 'div'"
      :href="link"
    >
      <div class="horizontal-banner-fi__text-container">
        <grid-container-fi class="horizontal-banner-fi__text-container__grid">
          <div
            :class="{
              'horizontal-banner-fi__right-banner-text': Boolean(fields.textRight?.value),
              'horizontal-banner-fi__banner-text': !Boolean(fields.textRight?.value),
            }"
          >
            <richtext-fi
              :is-theme-light="Boolean(fields.mode?.value === 'dark')"
              :html-content="fields.content"
            />
            <span
              v-if="link"
              class="fi-text-size-df-30"
            >
              <arrow-icon
                :class="{
                  'horizontal-banner-fi__banner-text__arrow': true,
                  'horizontal-banner-fi__banner-text__arrow--dark': Boolean(
                    fields.mode?.value === 'light',
                  ),
                }"
              />
            </span>
          </div>
        </grid-container-fi>
      </div>

      <div class="horizontal-banner-fi__banner-image">
        <picture
          v-if="!isEditMode && fields.image?.value?.src"
          :key="isMounted ? 'dom' : 'ssr'"
        >
          <source
            media="(max-width: 767px)"
            :srcset="
              appendParams(
                `${getProxySrc(fields.imageMobile?.value?.src || fields.image.value.src)}`,
                mobileImageParams,
              )
            "
          />
          <source
            media="(max-width: 1439px)"
            :srcset="
              appendParams(
                `${getProxySrc(fields.imageTablet?.value?.src || fields.image.value.src)}`,
                tabletImageParam,
              )
            "
          />
          <img
            :src="appendParams(`${getProxySrc(fields.image.value.src)}`, imageParam)"
            :alt="fields.image.value.alt || null"
          />
        </picture>
        <!-- eslint-disable vue/no-v-html -->
        <div
          v-else-if="isEditMode"
          v-html="fields.image.editable"
        />
        <!-- eslint-enable vue/no-v-html -->
      </div>
    </component>
  </component-wrapper>
</template>

<script setup lang="ts">
import { ArrowIcon, RichtextFi } from 'atoms/index';
import ComponentWrapper from 'components/component-wrapper/ComponentWrapper.vue';
import GridContainerFi from 'components/grid-fi/GridContainerFi.vue';
import { useCloudImage } from 'composables/cloudImage';
import { computed, onMounted, ref } from 'vue';

import type { CheckboxField, DropDown, ImageField, LinkField } from '@/sitecoreFieldTypes';

const props = withDefaults(
  defineProps<{
    fields: {
      image?: ImageField;
      link?: LinkField;
      content?: string;
      doNotModifyImage?: CheckboxField;
      mode?: DropDown;
      textRight?: CheckboxField;
    };
  }>(),
  {
    fields: () => ({}),
  },
);

const { getProxySrc, appendParams } = useCloudImage();

const isMounted = ref(false);

onMounted(() => {
  isMounted.value = true;
});

const isDark = computed<boolean>(() => {
  return props.fields?.mode?.value === 'dark';
});

const isLight = computed<boolean>(() => {
  return props.fields?.mode?.value === 'light';
});

const link = computed<string | null>(() => {
  const url = props.fields?.link?.value?.href || props.fields?.link?.value?.url;
  if (url && url !== '') {
    return url;
  }
  return null;
});

const hasdoNotModifyImage = computed<boolean>(() => {
  return !!props.fields?.doNotModifyImage?.value;
});

const bgColorise = computed<string>(() => {
  if (props.fields?.doNotModifyImage && props.fields?.doNotModifyImage.value) {
    return '';
  } else {
    if (isDark.value) {
      return '&colorize=black';
    } else {
      return '&colorize=white';
    }
  }
});
const imageParam = computed<string>(() => {
  return `h=480&aspect_ratio=16:4&func=crop&org_if_sml=0${bgColorise.value}`;
});
const tabletImageParam = computed<string>(() => {
  return `h=720&aspect_ratio=16:6&func=crop&org_if_sml=0${bgColorise.value}`;
});
const mobileImageParams = computed<string>(() => {
  return `h=767&aspect_ratio=16:14&func=crop&org_if_sml=0&gravity=north${bgColorise.value}`;
});
</script>

<style scoped lang="scss">
@import './horizontal-banner-fi';
</style>

<style lang="scss">
@import './horizontal-banner-fi-unscoped';
</style>
